import { Card, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import thanks from "assets/thanks.jpg"

const TopGrid = ({ isActive }) => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundImage: `url(${thanks})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Card
        className={` ${
          isActive
            ? "animate__animated animate__backInDown animate__delay-1s"
            : ""
        }`}
        sx={{
          px: 5,
          py: 2,
          bgcolor: "primary.main",
        }}
      >
        <Typography
          className="text-gradient"
          align="center"
          variant="h2"
          fontWeight="bold"
          sx={{
            borderBottom: "5px solid var(--primary)",
            borderTop: "5px solid var(--primary)",
          }}
        >
          Thank You!
        </Typography>
        <Typography
          className="text-gradient"
          align="center"
          variant="h6"
          fontWeight="bold"
          sx={{
            borderBottom: "5px solid var(--primary)",
            borderTop: "5px solid var(--primary)",
          }}
        >
          For Visiting QuCode Limited.
        </Typography>
      </Card>
    </Box>
  )
}

export default TopGrid
