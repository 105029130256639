import { Button, Container } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import secondGrid from "assets/Home/2ndGrid.jpg"
import { useStateContext } from "context/Context"

const SecondGrid = () => {
  const { activeHome, swiperMobileHome } = useStateContext()
  const isActive = activeHome.active === 1 || activeHome.prev === 1

  return (
    <Box
      className="height-100"
      sx={{
        width: "100%",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(242, 244, 248)),url(${secondGrid})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <Container
        sx={{
          height: "100%",
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Button
          onClick={() => swiperMobileHome.slideTo(0)}
          variant="outlined"
          color="primary"
          sx={{
            overflow: "hidden",
          }}
        >
          <Typography
            variant="h5"
            align="left"
            color="primary"
            fontWeight="bold"
            className={` ${
              isActive
                ? "animate__animated animate__fadeInLeft animate__delay-1s"
                : ""
            }`}
          >
            About Us
          </Typography>
        </Button>

        <Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              variant="subtitle2"
              gutterBottom
            >
              DIGITAL SOLUTIONS
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Let's build the <br /> Digital future together.
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              sx={{
                typography: {
                  xs: "subtitle1",
                },
              }}
              gutterBottom
            >
              QuCode Limited is a fast-growing startup and software development
              company specializing in delivering personalized, and tailored
              services to the clients across various industries. We help
              businesses to improve their operational efficiency, enhance
              customer experience, and drive business growth.
            </Typography>
          </Box>
          <br />
        </Box>
      </Container>
    </Box>
  )
}

export default SecondGrid
