import CallIcon from "@mui/icons-material/Call"
import EmailIcon from "@mui/icons-material/Email"
import FmdGoodIcon from "@mui/icons-material/FmdGood"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { Container, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import bg from "assets/background.jpg"

const RightGrid = ({ isActive }) => {
  return (
    <Box
      className="height-100"
      sx={{
        width: "100%",
        bgcolor: "primary.main",
        color: "primary.contrastText",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), 
            url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",

          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                fontSize: "2.5rem",
              }}
            >
              QuCode Limited
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Box
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInLeft animate__delay-1s"
                  : ""
              }`}
              sx={{
                background: "var(--gradient)",
                mb: 2,
                width: 150,
                height: 3,
                bgcolor: "secondary.main",
              }}
            />
          </Box>
          <br />
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              variant="h6"
              gutterBottom
              component="a"
              target="_blank"
              href="https://goo.gl/maps/3W1rngCZAnKozbMCA"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
            >
              <FmdGoodIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              Khilgaon, Dhaka, Bangladesh
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
              align="left"
              variant="h6"
              gutterBottom
            >
              <EmailIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              contact@qucode.tech
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              variant="h6"
              gutterBottom
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
            >
              <CallIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              +8801739700228
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
              align="left"
              variant="h6"
              gutterBottom
            >
              <LinkedInIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              Linkedin Profile
            </Typography>
          </Box>
          {/* <Grid
            container
            spacing={5}
            sx={{
              mt: 5,
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                >
                  <Linkedin
                    name="Newaz"
                    img={newaz}
                    job="CXO"
                    link="https://www.linkedin.com/in/saif-newaz"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                  s
                >
                  <Linkedin
                    name="Omar"
                    img={omar}
                    job="CTO"
                    link="https://www.linkedin.com/in/omar-hasan-79b64a125"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                >
                  <Linkedin
                    name="Hasin"
                    img={hasin}
                    job="CMO"
                    link="https://www.linkedin.com/in/hasin-ishraq-sajid/"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </Container>
    </Box>
  )
}

export default RightGrid
